import { Link } from 'react-router-dom';

export default function SinglePricePlanThree(props) {
  const { activePlan, planTitle, price, priceText, pricingTagLine, pricingDesc, btnStyle, btnLink, btnText, backgroundImage } = props;

  const ulList = pricingDesc.map((ele, index) => (
    <li key={index} className={`availability-${ele.availability}`}>
      {ele.text}
      {ele.upcoming && <span className="badge text-white bg-success rounded fs-8 fr">coming soon</span>}
    </li>
  ));

  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div className={`card pricing-card ${activePlan}`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/${backgroundImage})` }}>
        <div className="row">
          <div className="col-12 col-sm-8 col-lg-9 col-xl-9">
            <div className="pricing-heading">
              <div className="price">
                <span className={`badge bg-primary rounded fz-12`}>{planTitle}</span>
                <h1 className="mb-0 fw-bold text-dark">{price}</h1>
                <span className="fz-12 d-block">{priceText}</span>
              </div>
            </div>
            <div>
              <br></br>
              <h6>{pricingTagLine}</h6>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-lg-3 col-xl-3">
            <div className="pricing-desc">
              <ul className="ps-0 mb-0 list-unstyled">{ulList}</ul>
            </div>
            <br></br>
            <div className="pricing-btn">
              <Link className={`btn btn-sm ${btnStyle}`} to={btnLink}>
                {btnText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
