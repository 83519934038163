import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BlogHeading from '../heading/BlogHeading';
import BlogData from '../../data/BlogPages/index';

export default function BlogShowcase() {
  const BlogSlice = BlogData.filter((d) => d.showCaseDown);

  const BlogItems = BlogSlice.map((ele, index) => (
    <div key={index} className="col-12 col-sm-10 col-md-6 col-lg-4">
      <div className="card blog-card border-0">
        <Link className="image-wrap d-block" to={`${process.env.PUBLIC_URL}/blog/${ele.path}`}>
          <LazyLoadImage src={`${process.env.PUBLIC_URL}/${ele.image}`} alt={ele.title} />
        </Link>
        <div className="card-body px-4 pb-0">
          {ele.catagory}
          <Link className="post-title d-block mb-3" to={`${process.env.PUBLIC_URL}/blog/${ele.path}`}>
            {ele.title}
          </Link>
          <div className="post-meta">
            <span className="text-muted fz-14">
              <i className="bi bi-clock me-2" />
              {ele.readTime}
            </span>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="news-area news2 pt-100">
      <BlogHeading />

      <div className="container">
        <div className="row justify-content-center g-4 g-md-5 g-lg-4 g-xl-5">{BlogItems}</div>
      </div>
    </div>
  );
}
