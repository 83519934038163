import React from 'react';

export default function BlogPage() {
  return (
    <>
      <div>
        <p>GDPR that requires businesses to protect the personal data and privacy of EU region based users</p>
        <p className="text-primary">As of 25 May 2018, all organisations are expected to be compliant with GDPR.</p>
        <h5>What is GDPR ?</h5>
        <p>
          The General Data Protection Regulation (GDPR) is a regulation of the European Union (EU) that became effective on May 25, 2018. It strengthens and
          builds on the EU&#39;s current data protection framework, the General Data Protection Regulation (GDPR) replaces the 1995 Data Protection Directive.
        </p>
        <h5>What is GDPR Compliance?</h5>
        <p>
          Under the terms of GDPR, not only do organisations have to ensure that personal data is gathered legally and under strict conditions, but those who
          collect and manage it are obliged to protect it from misuse and exploitation, as well as to respect the rights of data owners - or face penalties for
          not doing so.
        </p>
        <h5>How to identify EU Users to be compliant ?</h5>
        <p>
          Using IPFindable.io, You can get the user&#39;s location and our apis directly gives the <code>is_eu</code> parameter with true or false, Hence after
          identifying you can have the process or special steps to protect the user or user groups.
        </p>
      </div>
    </>
  );
}
