import { Link } from 'react-router-dom';
import BlogData from '../../data/BlogPages/index';

export default function BlogSidebar() {
  const RecentPosts = BlogData.filter((d) => d.showcaseSidebar);

  const RecentPostItems = RecentPosts.map((ele, index) => (
    <div key={index} className="single-recent-post d-flex align-items-center">
      <div className="post-thumb">
        <Link className="rounded" to={`${process.env.PUBLIC_URL}/blog/${ele.path}`}>
          <img className="rounded" src={`${process.env.PUBLIC_URL}/${ele.image}`} alt={ele.title} />
        </Link>
      </div>
      <div className="post-content">
        <Link className="post-title" to={`${process.env.PUBLIC_URL}/blog/${ele.path}`}>
          {ele.title}
        </Link>
        <p className="post-date">{ele.date}</p>
      </div>
    </div>
  ));

  return (
    <div className="blog-sidebar-area">
      {/* Widget Area */}
      <div className="single-widget-area mb-4 mb-lg-5">
        <h4 className="widget-title mb-30">Recent Posts</h4>

        {RecentPostItems}
      </div>
    </div>
  );
}
