import { useState } from 'react';
// import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { CopyBlock, monokaiSublime as theme } from 'react-code-blocks';

export default function MainPageHeader(props) {
  const { BgShapeImage, title } = props;
  const [code] = useState(
    JSON.stringify(
      {
        ip: '108.66.187.246',
        cidr: '108.64.0.0/13',
        continent_code: 'NA',
        continent_name: 'North America',
        country_code2: 'US',
        country_code3: 'USA',
        country_name: 'United States of America',
        country_flag: 'https://ipfindable.io/flags/us.png',
        country_emoji: '🇺🇸',
        is_eu: false,
        country_capital: 'Washington, D.C.',
        state: 'Texas',
        city: 'Richardson',
        zipcode: '75080',
        latitude: '32.948180',
        longitude: '-96.729720',
        calling_code: '+1',
        languages: ['en'],
        isp: '7018',
        connection_type: null,
        organization: 'AT&T Services Inc.',
        asn: 'AS7018',
        currency: { code: 'USD', name: 'United States dollar', symbol: '$' },
        time_zone: {
          name: 'America/Chicago',
          offset: '-05:00',
          current_time: '2022-06-11 23:15:58.484-0500',
          current_time_unix: '1655007358484',
          is_dst: true
        },
        security: {
          is_tor: false,
          is_proxy: false,
          proxy_type: false,
          is_known_attacker: false,
          is_cloud_provider: false
        },
        ip_type: {
          version: 4,
          ipDecimal: 1816312822,
          isPublic: true,
          isPrivate: false,
          isLoopback: false,
          isBogun: false
        }
      },
      null,
      4
    )
  );

  return (
    <div className="welcome-area hero2 bg-white">
      {/* Shape */}
      <div className="hero-background-shape">
        <img src={`${process.env.PUBLIC_URL}/${BgShapeImage}`} alt={title} />
      </div>

      {/* Animation */}
      <div className="background-animation">
        <div className="item4" />
        <div className="item5" />
      </div>

      <div className="hero2-big-circle" />

      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-between">
          {/* Welcome Content */}
          <div className="col-12 col-sm-10 col-md-6">
            <div className="welcome-content">
              <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={100} animateOnce={true}>
                <h1 className="main-page-title">{title}</h1>
                <div style={{ marginLeft: '-20px' }}>
                  <span className="badge bgt-blue rounded fz-12 mt-2 ml-2">IPv4</span>
                  <span className="badge bgt-blue rounded fz-12 mt-2 ml-2">IPv6</span>
                  <span className="badge bgt-blue rounded fz-12 mt-2 ml-2">Location</span>
                  <span className="badge bgt-blue rounded fz-12 mt-2 ml-2">Timezone</span>
                  <span className="badge bgt-blue rounded fz-12 mt-2 ml-2">Threat</span>
                  <span className="badge bgt-blue rounded fz-12 mt-2 ml-2">Language</span>
                  <span className="badge bgt-blue rounded fz-12 mt-2 ml-2">Currency</span>
                  <span className="badge bgt-blue rounded fz-12 mt-2 ml-2">Network</span>
                  <span className="badge bgt-blue rounded fz-12 mt-2 ml-2">ASN</span>
                  <span className="badge bgt-blue rounded fz-12 mt-2 ml-2">Proxy</span>
                  <span className="badge bgt-blue rounded fz-12 mt-2 ml-2">Compliance</span>
                </div>
              </ScrollAnimation>

              <ScrollAnimation className="mt-2" animateIn="fadeInUp" animateOut="fadeInOut" delay={100} animateOnce={true}></ScrollAnimation>

              <div className="welcome-actions">
                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={100} animateOnce={true}>
                  <a className={`btn bgt-blue text-white`} href="#">
                    Sign up for free
                  </a>
                  <h6 className="mt-1 text-center">
                    <small>Free 10K requests per month</small>
                  </h6>
                </ScrollAnimation>
                {/* TODO: Enable on login */}
                {/* <ScrollAnimation className="nav-link" animateIn="fadeInUp" animateOut="fadeInOut" delay={100} animateOnce={true}>
                  <Link className={`main-api-docs-btn btn`} to="/documentation">
                    API Docs
                  </Link>
                </ScrollAnimation> */}
              </div>
            </div>
          </div>

          {/* Welcome Thumb */}
          <div className="col-12 col-sm-10 col-md-6">
            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={100} animateOnce={true}>
              <div className="code-editor-front-div">
                <div className="newsletter-form mb-4">
                  <form
                    className="d-flex align-items-stretch"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <input className="form-control rounded-start lookup-search-input" disabled type="text" placeholder="IPv4 or IPv6 Lookup" />
                    <button className="btn bgt-grey rounded-end lookup-search-btn" type="submit">
                      <i className="bi bi-search" />
                    </button>
                  </form>
                </div>
                <div className="code-editor-front">
                  <CopyBlock text={code} language={'json'} showLineNumbers={false} theme={theme} />
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
}
