import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export default function SinglePriceTable(props) {
  const {
    planSwitch,
    activePlan,
    planTitle,
    monthlyPrice,
    monthlyPriceInr,
    monthlyPriceText,
    yearlyPrice,
    yearlyPriceInr,
    yearlyPriceText,
    pricingTagLine,
    pricingTagLineHover,
    pricingDesc,
    btnStyle,
    btnText
  } = props;

  const pricingDescArr = pricingDesc;
  const pricingItems = pricingDescArr.map((pricingItem, index) => (
    <li key={index} className={`availability-${pricingItem.availability}`}>
      {pricingItem.text}
      {pricingItem.upcoming && <span className="badge text-white bg-success rounded fs-8 fr">coming soon</span>}
    </li>
  ));

  return (
    <div className="col-12 col-sm-9 col-md-7 col-lg-3">
      <div className={`card pricing-card ${planSwitch} mb-30 ${activePlan}`}>
        <div>
          <span className={`badge bg-${btnStyle} rounded fz-12 fl`}>{planTitle}</span>
          {yearlyPriceText === 'per year' && planSwitch === 'yearly-plan' && (
            <span className="fz-12">
              <span className={`yearly-price badge bg-danger rounded fz-12 fr`}>2 Months Free</span>
            </span>
          )}
        </div>
        <div className="pricing-heading d-flex justify-content-between mb-5">
          <div className="price mb-5">
            <div className="price-quantity">
              <h2 className="mb-0 monthly-price">
                {monthlyPrice}
                <OverlayTrigger
                  key={monthlyPrice}
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id={`tooltip-monthlyPrice`}>₹ - INR Pricing</Tooltip>}
                >
                  <p className="fz-12 pointer mb-0">&nbsp;{monthlyPriceInr}</p>
                </OverlayTrigger>
                <span className="fz-12">{monthlyPriceText}</span>
              </h2>
              <h2 className="mb-0 yearly-price">
                {yearlyPrice}
                <OverlayTrigger
                  key={monthlyPrice}
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id={`tooltip-monthlyPrice`}>₹ - INR Pricing</Tooltip>}
                >
                  <p className="fz-12 pointer mb-0">&nbsp;{yearlyPriceInr}</p>
                </OverlayTrigger>
                <span className="fz-12">{yearlyPriceText}</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <h6 className="mb-0" title={pricingTagLineHover}>
            {pricingTagLine}
          </h6>
          <span className="fz-12" title={pricingTagLineHover}>
            per month
          </span>
        </div>
        <br></br>
        <div className="pricing-desc mb-5">
          <ul className="list-unstyled mb-0">{pricingItems}</ul>
        </div>

        <div className="pricing-btn">
          <a className={`btn btn-${btnStyle}`} href="https://app.ipfindable.io/auth/signup">
            {btnText}
          </a>
        </div>
      </div>
    </div>
  );
}
