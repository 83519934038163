import Header from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import UseCases from '../components/main/UseCases';
import BlogShowcase from '../components/blog/BlogShowcase';
import Divider from '../components/common/Divider';
import FeaturesSection from '../components/main/FeaturesSection';
import MainPageHeader from '../components/main/MainPageHeader';
import SEO from '../components/common/SEO';

export default function MainPage(props) {
  return (
    <div className="mainpage-wrap">
      <SEO title="Free IP Geolocation and Information Lookup API - Country, Connection, Currency, Threat" />

      <Header
        brandLogo="assets/img/core-img/ipfindable-io-logo-transparent-250x90.webp"
        headerStyle="header-2"
        buttonText="Get Started"
        buttonColor="btn-primary"
      />

      <MainPageHeader
        BgShapeImage="assets/img/core-img/hero-2.png"
        title="IP Geolocation and Information Lookup API"
        subtitle="It's crafted with the latest trend of design &amp; coded with all modern approaches. It's a robust &amp; multi-dimensional usable template."
      />

      <FeaturesSection hash={props.hash} />

      <UseCases />

      <BlogShowcase />

      <Divider />

      <Footer footerLogo="assets/img/core-img/ipfindable-io-logo-white-250x90.webp" footerStyle="footer-2" />
    </div>
  );
}
