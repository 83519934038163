import ScrollAnimation from 'react-animate-on-scroll';

export default function BlogHeading() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-10 col-md-9 col-lg-8 col-xxl-8">
          <div className="section-heading text-center">
            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={100} animateOnce={true}>
              <h6 className="text-dark">Blog</h6>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={100} animateOnce={true}>
              <h4>Checkout our latest updates.</h4>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
}
