import Header from '../components/navbar/Navbar';
import BreadcrumbTwo from '../components/breadcrumb/BreadcrumbTwo';
import Footer from '../components/footer/Footer';
import Divider from '../components/common/Divider';
import SEO from '../components/common/SEO';

export default function TermsPage() {
  return (
    <div>
      <SEO title="Terms and Conditions | Privacy Policy" canonicalPath={'/terms-and-conditions'} />

      <Header brandLogo="assets/img/core-img/ipfindable-io-logo-transparent-250x90.webp" headerStyle="header-2" />

      <BreadcrumbTwo breadcrumbTitle="Terms and Conditions - Privacy Policy" homePageUrl="/" homePageText="Home" />

      <Divider />

      <div className="faq--area">
        <div className="container">
          <div className="p-20">
            <br />
            <h1>Terms And Conditions &amp; Privacy Policy</h1>
            <small>Last Updated: 13, July 2022</small>
            <br />
            <br />
            <h3 id="terms-conditions">1. Terms &amp; Conditions</h3>
            <br />
            <strong>AGREEMENT TO TERMS</strong>
            <br />
            <p>
              These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and{' '}
              cubepost services or
              <a href="https://ipfindable.io">ipfindable.io</a> (“we,” “us” or “our”), concerning your access to and use of the ipfindable.io website as well as
              any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the
              “Site”).
            </p>
            <br />
            <p>
              You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree
              with all of these Terms and Conditions, then you are expressly prohibited from using the Site and you must discontinue use immediately.
            </p>
            <p>
              Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by
              reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms and Conditions at any time and for any
              reason.
            </p>
            <p>
              We will alert you about any changes by updating the “Last updated” date of these Terms and Conditions, and you waive any right to receive specific
              notice of each such change.
            </p>
            <p>
              It is your responsibility to periodically review these Terms and Conditions to stay informed of updates. You will be subject to, and will be
              deemed to have been made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the Site after
              the date such revised Terms and Conditions are posted.
            </p>
            <p>
              The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such
              distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or
              country.
            </p>
            <p>
              Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for
              compliance with local laws, if and to the extent local laws are applicable.
            </p>
            <p>
              All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly
              supervised by, their parent or guardian to use the Site. If you are a minor, you must have your parent or guardian read and agree to these Terms
              and Conditions prior to you using the Site.
            </p>
            <br />
            <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
            <p>
              Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio,
              video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein
              (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual
              property rights and unfair competition laws of the United States, foreign jurisdictions, and international conventions.
            </p>
            <p>
              The Content and the Marks are provided on the Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms
              and Conditions, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without
              our express prior written permission.
            </p>
            <p>
              Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of
              any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not
              expressly granted to you in and to the Site, the Content and the Marks.
            </p>
            <br />
            <strong>PROHIBITED ACTIVITIES BY USERS OF THIS WEBSITE BY DIRECT OR INDIRECT MEANS</strong>
            <br />
            <p>
              You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection
              with any commercial endeavors except those that are specifically endorsed or approved by us.
            </p>
            <b>As a user of the Site, you agree not to:</b>
            <ul>
              <li>
                systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database,
                or directory without written permission from us.
              </li>
              <li>
                make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the
                purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses. use a buying agent or purchasing
                agent to make purchases on the Site.
              </li>
              <li>use the Site to advertise or offer to sell goods and services.</li>
              <li>
                circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or
                copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.
              </li>
              <li>engage in unauthorized framing of or linking to the Site.</li>
              <li>
                trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords; make
                improper use of our support services or submit false reports of abuse or misconduct.
              </li>
              <li>
                engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data
                gathering and extraction tools.
              </li>
              <li>interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.</li>
              <li>attempt to impersonate another user or person or use the username of another user.</li>
              <li>sell or otherwise transfer your profile.</li>
              <li>use any information obtained from the Site in order to harass, abuse, or harm another person.</li>
              <li>
                use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or
                commercial enterprise.
              </li>
              <li>decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site.</li>
              <li>attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.</li>
              <li>harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you.</li>
              <li>delete the copyright or other proprietary rights notice from any Content.</li>
              <li>copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
              <li>
                upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters
                and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies,
                impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site.
              </li>
              <li>
                upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission
                mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices
                (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).
              </li>
              <li>
                except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system,
                including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any
                unauthorized script or other software.
              </li>
              <li>disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>
              <li>use the Site in a manner inconsistent with any applicable laws or regulations.</li>
            </ul>
            <br />
            <strong>CORRECTIONS</strong>
            <p>
              There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may relate to the Site, including
              descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to
              change or update the information on the Site at any time, without prior notice.
            </p>
            <strong>DISCLAIMER</strong>
            <p>
              THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE
              FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING,
              WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
              REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
              LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
              NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND
              ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5)
              ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
              OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED,
              OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
              OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
              ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS
              OF PRODUCTS OR SERVICES.
            </p>
            <p>
              AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
              WHERE APPROPRIATE.
            </p>
            <strong>LIMITATIONS OF LIABILITY</strong>
            <p>
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY,
              INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE,
              EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <br />
            <strong>INDEMNIFICATION</strong>
            <p>
              You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents,
              partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made
              by any third party due to or arising out of: (1) [your Contributions]; (2) use of the Site; (3) breach of these Terms and Conditions; (4) any
              breach of your representations and warranties set forth in these Terms and Conditions; (5) your violation of the rights of a third party,
              including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the Site with whom you connected
              via the Site.
            </p>
            <p>
              Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are
              required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify
              you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
            </p>
            <strong>DATA</strong>
            <p>
              We will maintain certain data that you transmit to the Site for the purpose of managing the Site, as well as data relating to your use of the
              Site. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any
              activity you have undertaken using the Site.
            </p>
            <p>
              You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us
              arising from any such loss or corruption of such data.
            </p>
            <p>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</p>
            <p>
              Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic
              communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and
              on the Site, satisfy any legal requirement that such communication be in writing.
            </p>
            <p>
              YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
              RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.
            </p>
            <p>
              You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an
              original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than
              electronic means.
            </p>
            <strong>CALIFORNIA USERS AND RESIDENTS</strong>
            <p>
              If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the
              California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at
              (800) 952-5210 or (916) 445-1254.
            </p>
            <strong>MISCELLANEOUS</strong>
            <p>
              These Terms and Conditions and any policies or operating rules posted by us on the Site constitute the entire agreement and understanding between
              you and us. Our failure to exercise or enforce any right or provision of these Terms and Conditions shall not operate as a waiver of such right or
              provision.
            </p>
            <p>
              These Terms and Conditions operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at
              any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.
            </p>
            <p>
              If any provision or part of a provision of these Terms and Conditions is determined to be unlawful, void, or unenforceable, that provision or part
              of the provision is deemed severable from these Terms and Conditions and does not affect the validity and enforceability of any remaining
              provisions.
            </p>
            <p>
              There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms and Conditions or
              use of the Site. You agree that these Terms and Conditions will not be construed against us by virtue of having drafted them.
            </p>
            <p>
              You hereby waive any and all defenses you may have based on the electronic form of these Terms and Conditions and the lack of signing by the
              parties hereto to execute these Terms and Conditions.
            </p>
            <br />
            <br />
            <h3 id="privacy-policy">2. Privacy Policy</h3>
            <br />
            <p>
              This Cookie Policy explains how cookies and similar technologies (collectively, “Cookie(s)”) are used when you visit our Site. A “Site” includes
              our websites, emails, and other applications owned and operated by ipfindable.io (the “Company”, “our”, or “us”) as well as any other services
              that display this Cookie Policy. This policy explains what these technologies are and why they are used, as well as your right to control their
              use.
            </p>
            <p>
              We may change this Cookie Policy at any time. Please take a look at the “LAST REVISED” date at the top of this page to see when this Cookie Policy
              was last revised. Any change in this Cookie Policy will become effective when we make the revised Cookie Policy available on or through the Site.
            </p>
            <h4>Cookies Do We Use?</h4>
            <p>
              Below we list the different types of Cookies that are used on the Site that you are visiting. To the extent any personal information is collected
              through first-party Cookies, our Privacy Policy applies and complements this Cookie Policy. Personal information collected through a third-party
              Cookie is subject to the privacy policy of that third party, and not our Privacy Policy.
            </p>
            <strong>Essential Cookies</strong>
            <p>
              Essential Cookies enable you to navigate the Site and to use its services and features. Without these necessary Cookies, the Site will not perform
              as smoothly for you as we would like it to and we may not be able to provide the Site or certain services or features.
            </p>
            <strong>Preference Cookies</strong>
            <p>
              Preference Cookies collect information about your choices and preferences, and allow us to remember language or other local settings and customize
              the Site accordingly.
            </p>
            <strong>Social Media Cookies</strong>
            <p>
              {' '}
              Social media Cookies are used to enable you to share pages and content you find interesting on our Site through third-party social networking and
              other websites. These Cookies may also be used for advertising purposes.
            </p>
            <strong>Analytics Cookies</strong>
            <p>
              {' '}
              Analytics Cookies collect information about your use of the Site, and enable us to improve the way it works. For example, Analytics Cookies show
              us which are the most frequently visited pages on the Site, help us record any difficulties you have with the Site, and show us whether our
              advertising is effective or not. Analytics Cookies allow us to see the overall patterns of usage on the Site, rather than the usage of a single
              person. We use information from Analytics Cookies to analyze the Site traffic, but we do not examine this information for individually identifying
              information.
            </p>
            <br />
            <strong id="contact-us">CONTACT US</strong>
            <p>
              In order to resolve a complaint regarding the Site or to receive further information regarding use of the site, you can use the chat on the bottom
              right on page or please contact us at:
            </p>
            <br />
            <b>Email:</b>
            <br />
            <small>support@ipfindable.io</small>
            <br />
            <br />
            <b>Name and Address:</b>
            <br />
            <br />
            <b>Cubepost Services - IPFindable.io</b>
            <br />
            <p>
              #1934 - 301, 18th Main 16th A Cross,
              <br />
              AECS Layout A Block, Singasandra,
              <br />
              Bangalore - 560068
              <br />
              Karnataka, India
            </p>
            <p>Contact: +91 81222 60623</p>
            <br />
          </div>
        </div>
      </div>

      <Divider />

      <Footer footerLogo="assets/img/core-img/ipfindable-io-logo-white-250x90.webp" footerStyle="footer-2" />
    </div>
  );
}
