import PricingHeading from './PricingHeading';
import PricingTableOne from './PricingTableOne';
import PricingPlanEnterprise from './PricingPlanEnterprise';

export default function Pricing() {
  return (
    <div className="pricing-plan-area bg-gray pt-150 pb-80">
      {/* Section Heading */}
      <PricingHeading />
      <PricingTableOne />

      <div className="container">
        <div className="row justify-content-center g-4 g-lg-5">
          <PricingPlanEnterprise
            activePlan=""
            planTitle="Enterprise"
            price="Contact-Us"
            priceText="Yearly Free Credits"
            pricingTagLine="High volume / Custom Use cases"
            pricingDesc={[
              { text: 'All features in bussiness +', availability: 'yes' },
              { text: 'Custom Functions', availability: 'yes' },
              { text: 'Real Time Reporting', availability: 'yes' },
              { text: 'Auto - GeoFencing', availability: 'yes' }
            ]}
            btnStyle="btn-primary"
            btnLink="#"
            btnText="Sign up ->"
            backgroundImage=""
          />
        </div>
      </div>
    </div>
  );
}
