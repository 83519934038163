import { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavbarHeading from './NavbarLinks';
import useStickyHeader from './StickyHeader';

export default function Header(props) {
  const { brandLogo, headerStyle, backgroundColor, headerDivision } = props;

  let [check] = useState(true);
  const sticky = useStickyHeader(10);
  let stickyClass = `${sticky && check ? 'sticky' : ''}`;
  if (headerDivision) stickyClass = 'sticky';

  return (
    <header className={`header-area ${headerStyle} ${stickyClass} ${backgroundColor}`}>
      <Navbar expand="lg">
        <div className="container">
          {/* Navbar Brand */}
          <a href="/">
            <img className="navbar-brand-logo" src={`${process.env.PUBLIC_URL}/${brandLogo}`} alt="IPFindable.io Logo" />
          </a>

          {/* Navbar Toggler */}
          <Navbar.Toggle className="navbar-toggler" aria-controls="navbar" />

          <Navbar.Collapse id="navbar">
            {/* Nav */}
            <NavbarHeading />

            {/* Button */}
            <div className="ms-auto mb-3 mb-lg-0">
              <a className={`btn text-white bg-danger btn-sm`} href="https://app.ipfindable.io/auth/login">
                Login
              </a>
              <a className={`btn text-white bg-danger btn-sm ml-1`} href="https://app.ipfindable.io/auth/signup">
                Sign up for free
              </a>
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
}
