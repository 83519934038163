import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function FooterWidgetLogo(props) {
  const { footerLogo, footerText } = props;

  return (
    <div className="col-12 col-sm-6 col-lg-3">
      {/* Footer Widget */}
      <div className="footer-widget-area">
        <Link className="d-block" to="/">
          <LazyLoadImage src={`${process.env.PUBLIC_URL}/${footerLogo}`} alt="Footer-logo" style={{ marginLeft: '-15px', marginTop: '-20px' }} />
        </Link>
        <strong>
          <small>{footerText}</small>
        </strong>
      </div>
    </div>
  );
}
