import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Divider from '../components/common/Divider';
import Footer from '../components/footer/Footer';
import SEO from '../components/common/SEO';

export default function ComingSoon() {
  const ComingSoonData = [
    {
      image: 'assets/img/core-img/coming-soon-img.webp',
      title: 'Coming Soon',
      para: 'We are getting back with an exciting stunning website.'
    }
  ];

  return (
    <div>
      <SEO title="Coming Soon" canonicalPath={'/coming-soon'} />

      <div className="container">
        <div className="logo-area text-center py-4">
          <Link to="/">
            <LazyLoadImage src={`${process.env.PUBLIC_URL}/assets/img/core-img/ipfindable-io-logo-transparent-250x90.webp`} alt="logo" />
          </Link>
        </div>
      </div>

      <div className="container">
        <div className="border" />
      </div>

      <Divider />

      <div className="coming-soon-area text-center">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-7">
              <LazyLoadImage className="mb-80" src={`${process.env.PUBLIC_URL}/${ComingSoonData[0].image}`} alt="cs" />
              <h2>{ComingSoonData[0].title}</h2>
              <p>{ComingSoonData[0].para}</p>
              <Link className="btn btn-primary mt-4" to="/">
                Go to Home
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Divider />
      <Footer footerLogo="assets/img/core-img/ipfindable-io-logo-white-250x90.webp" footerStyle="footer-2" />
    </div>
  );
}
