import NotFoundContent from '../components/404/NotFoundContent';
import Divider from '../components/common/Divider';
import Footer from '../components/footer/Footer';
import Header from '../components/navbar/Navbar';
import SEO from '../components/common/SEO';

export default function NotFound() {
  return (
    <div>
      <SEO title="Not Found" canonicalPath={'/not-found'} />

      <Header brandLogo="assets/img/core-img/ipfindable-io-logo-transparent-250x90.webp" headerStyle="header-2" buttonText="Log In" buttonColor="btn-warning" />

      <NotFoundContent
        errorImage="assets/img/core-img/not-found-img.webp"
        errorText="Oops! Page not found"
        errorPara="We couldn't find any results for your search. Try again."
        btnText="Go to Home"
      />

      <Divider />

      <Footer footerStyle="footer-2" footerLogo="assets/img/core-img/ipfindable-io-logo-white-250x90.webp" />
    </div>
  );
}
