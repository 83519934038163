import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import ScrollTop from './components/navbar/ScrollTop';
import { BrowserRouter } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebaseConfig from './firebase-config';

if (!_.includes(['localhost', '127.0.0.1', '192.168.1.2', '192.168.1.3'], window.location.hostname)) {
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
