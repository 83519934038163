import ScrollAnimation from 'react-animate-on-scroll';

export default function BottomRibbon(props) {
  const { title, href, btnText } = props;

  return (
    <div className="bottom-ribbon-area cta3 py-5 bg-info">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-sm-8">
            {/* Cta Text */}
            <div className="cta-text mb-4 mb-sm-0">
              <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={100} animateOnce={true}>
                <h3 className="mb-0">{title}</h3>
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-12 col-sm-4 text-sm-end">
            {/* Cta Button */}
            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={300} animateOnce={true}>
              <a className="btn btn-warning btn-sm" href={href}>
                {btnText}
              </a>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
}
