import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function UseCases() {
  const aboutImage = 'assets/img/core-img/ipfinable-business-cases-image.webp';

  const workSteps = [
    {
      icon: 'bi-globe',
      title: 'Localization of your website.',
      para: 'Show user content based on the geographoic location - continent, country, state and city.',
      para2: 'display all time events in right order based on local timezone.'
    },
    {
      icon: 'bi-currency-pound',
      title: 'Show the pricing in local currency',
      para: 'Get the user local currency from API, Showing in local currency will have faster and higher conversion-rates to your platform.'
    },
    {
      icon: 'bi-pin-map',
      title: 'Geo Restrict your service',
      para: 'Apply geographic restriction to your service based on the location data served from the API for compliance and internal purpose.'
    },
    {
      icon: 'bi-hdd-network',
      title: 'Threat and Network Detection',
      para: 'Detect for SPAM, TOR, Proxy and AS organization to avoid abuse of your service based on the user IP address.'
    },
    {
      icon: 'bi-binoculars',
      title: 'Get users public IP address for audit',
      para: 'To store the users public IP address for audit purposes.'
    }
  ];

  const workStepsItem = workSteps.map((item, index) => (
    <div key={index} className="single-work-step d-flex mb-4">
      <div className={`feature-icon-usecase`}>
        <i className={`bi bi-check-lg text-success`} />
      </div>
      <div className="work-step-text">
        <h5>{item.title}</h5>
        <p className="mb-0">{item.para}</p>
        <p>{item.para2}</p>
      </div>
    </div>
  ));

  return (
    <div id="use-cases" className="bg-gray pt-120 pb-120">
      <div className="container">
        <div className="row g-5 align-items-center justify-content-md-center justify-content-lg-between">
          {/* About Us Thumbnail */}
          <div className="col-12 col-md-8 col-lg-6 col-xl-6">
            <div className="aboutUs-thumbnail">
              <LazyLoadImage className="w-100" src={`${process.env.PUBLIC_URL}/${aboutImage}`} alt="ipfinable-business-cases" />
            </div>
          </div>

          {/* About Us Content */}
          <div className="col-12 col-md-8 col-lg-6">
            <div className="aboutUs-content">
              <div className="section-heading mb-5">
                <h6>Use Cases</h6>
                <h2>How it brings diffrence to your business.</h2>
              </div>

              <div className="work-step-wrapper">{workStepsItem}</div>

              <a className={`btn btn-primary`} href="https://app.ipfindable.io/auth/signup">
                Sign up for free
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
