import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// Home Page
import MainPage from './pages/MainPage';
import PricingPage from './pages/PricingPage';

// Blog Page
import BlogOnePage from './pages/BlogPage';
import BlogDetailsPage from './pages/BlogDetailsPage';

// Documetation Page
import DocumetationPage from './pages/DocumentationPage';

// Others Page
import ContactUs from './pages/ContactUs';
import NotFound from './pages/NotFound';
import FaqPage from './pages/FaqPage';
import RefundPolicyPage from './pages/RefundPolicyPage';
import TermsPage from './pages/TermsPage';
import ComingSoon from './pages/ComingSoon';

// All CSS Import
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/all-css-libraries.css';
import '../node_modules/react-modal-video/scss/modal-video.scss';
import './assets/scss/style.scss';

export default function App() {
  const location = useLocation();
  const hash = location.hash;

  return (
    <div className="App">
      <Routes>
        <Route path="/" index element={<MainPage hash={hash} />} />
        <Route path="/pricing" element={<PricingPage hash={hash} />} />
        <Route path="/blog" element={<BlogOnePage hash={hash} />} />
        <Route path="/blog/:postId" element={<BlogDetailsPage hash={hash} />} />
        <Route path="/documentation" element={<DocumetationPage hash={hash} />} />

        <Route path="/contact-us" element={<ContactUs hash={hash} />} />
        <Route path="/faq" element={<FaqPage hash={hash} />} />
        <Route path="/coming-soon" element={<ComingSoon hash={hash} />} />
        <Route path="/terms-and-conditions" element={<TermsPage hash={hash} />} />
        <Route path="/refund-and-cancellation-policy" element={<RefundPolicyPage hash={hash} />} />

        <Route path="/not-found" element={<NotFound hash={hash} />} />
        <Route path="*" element={<Navigate to="/not-found" hash={hash} />} />
      </Routes>
    </div>
  );
}
