import Header from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import BlogShowcase from '../components/blog/BlogShowcase';
import Divider from '../components/common/Divider';
import Pricing from '../components/pricing/Pricing';
import SEO from '../components/common/SEO';

export default function PricingPage() {
  return (
    <div className="PricingPage-wrap">
      <SEO title="Plans & Pricing" canonicalPath={'/pricing'} />

      <Header
        headerDivision={true}
        brandLogo="assets/img/core-img/ipfindable-io-logo-transparent-250x90.webp"
        headerStyle="header-2"
        buttonText="Get Started"
        buttonColor="btn-primary"
        backgroundColor="bg-gray"
      />
      <div className="container">
        <div className="border-top"></div>
      </div>

      <Pricing />

      <BlogShowcase />

      <Divider />

      <Footer footerLogo="assets/img/core-img/ipfindable-io-logo-white-250x90.webp" footerStyle="footer-2" />
    </div>
  );
}
