import { Link } from 'react-router-dom';

export default function FooterWidgetNav(props) {
  const { heading, footerNav, size } = props;

  const listItems = footerNav.map((item, index) => (
    <li key={index}>
      {item.url && (
        <Link to={item.url}>
          {item.title}
          {item.soon && (
            <span className="badge bg-success rounded fz-8 ml-1">
              <small>soon</small>
            </span>
          )}
        </Link>
      )}
      {item.link && (
        <a href={item.link}>
          {item.title}
          {item.soon && (
            <span className="badge bg-success rounded fz-8 ml-1">
              <small>soon</small>
            </span>
          )}
        </a>
      )}
    </li>
  ));

  return (
    <div className={`col-12 col-sm-6 col-lg-${size}`}>
      {/* Footer Widget */}
      <div className="footer-widget-area">
        <p style={{ borderBottom: '1px dotted grey', display: 'inline-block' }} className="mb-4">
          {heading}
        </p>
        <ul className="list-unstyled">{listItems}</ul>
      </div>
    </div>
  );
}
