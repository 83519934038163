import React from 'react';

export default function BlogPage() {
  return (
    <>
      <div>
        <p>Changing website content based on a visitor&#39;s geo location to make the customer having a personalized experience.</p>
        <p>
          A customer in different part of the world needs a service personalized to his or her location.
          <br />
          <br />
          <b className="text-primary">Examples:</b>
          <br />
          <li> E-commerce store shows the website design and offsers specific to country.</li>
          <li>The price of the product changes according to the country and also the currency and exchange which is changes very frequently.</li>
          <li>The online grocery store or a professional service needs have a boundry based on city in order to serve their custmoer.</li>
        </p>
        <br />
        <h5>Why serve contect based on users geo location?</h5>
        <ul>
          <li>
            <strong className="text-primary">E-commerce</strong>
          </li>
          <p>
            - To show target customers their offers and products for their location.
            <br />
            - Setting the price dynamically based on the city or state.
            <br />
            - For international websites, Knowing the local currency and changing the rates based on exchange rates.
            <br />
            - Localizing the content to service if diffrent languages based on the users location and community.
            <br />
          </p>

          <li>
            <strong className="text-primary">Compliance</strong>
          </li>
          <p>
            - Certain countries and states like Europe, California etc has compliance laws like GDPR, CCPA which is striclty monitored.
            <br />
            - To detect the customers from those regions needs to be grouped, Hence IPFindable helps in getting the customer geo and also telling the used is
            European Union or not.
            <br />
          </p>

          <li>
            <strong className="text-primary">Banking and Payments</strong>
          </li>
          <p>
            - Users IP needs to be stored and need to get the location data from the backed system to do fraud detection and save it for audit purpose.
            <br />
            - Restrict payments only to certain regions.
            <br />
          </p>

          <li>
            <strong className="text-primary">Online Services</strong>
          </li>
          <p>
            - Notifing the users at right time based the user location to have a high CTA on the notification.
            <br />
            - Detect the spammy proxies by bots and malicious users.
            <br />
          </p>
        </ul>
      </div>
    </>
  );
}
