import React, { useRef, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SectionHeading from '../heading/HeadingOne';
import ScrollAnimation from 'react-animate-on-scroll';

const scrollToFeatures = (ref) => window.scrollTo(0, ref.current.offsetTop);

export default function FeaturesSection(props) {
  const featuresRef = useRef(null);

  useEffect(() => {
    if (props.hash === '#features') scrollToFeatures(featuresRef);
  });

  const featuresData = [
    {
      iconColor: '',
      icon: 'bi-geo-alt',
      title: 'Geolocation Data',
      para: 'Locate website visitors IP, country, state, city and postal code granularity.',
      animationDelay: 0
    },
    {
      iconColor: '',
      icon: 'bi-clock-history',
      title: 'Timezone',
      para: 'Get visitor timezone to serve right content at right time.',
      animationDelay: 100
    },
    {
      iconColor: '',
      icon: 'bi-translate',
      title: 'Language',
      para: 'Localize your website content to visitors native language automatically.',
      animationDelay: 100
    },
    {
      iconColor: '',
      icon: 'bi-currency-dollar',
      title: 'Currency',
      para: 'Curency symbol and codes based on the country.',
      animationDelay: 100
    },
    {
      badgeTxt: 'coming soon',
      iconColor: '',
      icon: 'bi-currency-exchange',
      title: 'Live Exchange',
      para: "Live Currency exchange rates for visitor's local currency.",
      animationDelay: 100
    },
    {
      iconColor: '',
      icon: 'bi-hdd-network',
      title: 'Connection',
      para: 'Get the AS Organization, number and more IP and network information.',
      animationDelay: 100
    },
    {
      iconColor: '',
      icon: 'bi-person-x',
      title: 'Threat',
      para: 'Detect anonymous user from proxy or fraud based on past feeds.',
      animationDelay: 100
    },
    {
      iconColor: '',
      icon: 'bi-node-plus',
      title: 'Fresh',
      para: 'IP Data updated frequently with hot loading of data.',
      animationDelay: 100
    }
  ];

  const featuresItems = featuresData.map((ele, index) => (
    <div key={index} className="col-12 col-sm-6 col-lg-3">
      <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={ele.animationDelay} animateOnce={true}>
        <div className="card feature2-card shadow-lg">
          <div className="card-body">
            <span className="badge text-white bg-success rounded fz-12 fr">{ele.badgeTxt}</span>
            <div className={`feature-icon mb-4 ${ele.iconColor}`}>
              <i className={`bi ${ele.icon}`} />
            </div>
            <h5>{ele.title}</h5>
            <p>{ele.para}</p>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  ));

  return (
    <div className="feature-area feature2 pt-80 pb-50" id="features" ref={featuresRef}>
      <SectionHeading
        subtitle="Why choose us"
        subtitleColor="text-dark"
        heading="We have been running many successful business studios."
        para="It's crafted with the latest trend of design &amp; coded with all modern approaches. It's a robust &amp; multi-dimensional usable template."
        paraColor="text-dark"
      />

      <div className="container">
        <div className="row g-4 g-xxl-5">{featuresItems}</div>
      </div>

      <div className="mt-5 text-center">
        <h6>Powered By</h6>
        <LazyLoadImage style={{ opacity: 0.8 }} src={`${process.env.PUBLIC_URL}/assets/img/partner-img/aws-icon.webp`} alt="logo" />
        <LazyLoadImage style={{ opacity: 0.8 }} className="ml-2" src={`${process.env.PUBLIC_URL}/assets/img/partner-img/cloudflare-icon.webp`} alt="logo" />
      </div>
    </div>
  );
}
