import BreadcrumbTwo from '../components/breadcrumb/BreadcrumbTwo';
import Divider from '../components/common/Divider';
import Header from '../components/navbar/Navbar';
import BottomRibbon from '../components/common/BottomRibbon';
import Footer from '../components/footer/Footer';
import BlogCard from '../components/blog/BlogCard';
import BlogData from '../data/BlogPages/index';
import SEO from '../components/common/SEO';

export default function BlogPage() {
  const BlogCardsItems = BlogData.map((item, index) => <BlogCard key={index} data={item} />);

  return (
    <>
      <SEO title="Blog" canonicalPath={'/blog'} />

      <Header brandLogo="assets/img/core-img/ipfindable-io-logo-transparent-250x90.webp" headerStyle="header-2" />

      <BreadcrumbTwo breadcrumbTitle="Blog" homePageUrl="/" homePageText="Home" currentPageText="Blog" />

      <Divider />

      <div className="blog-area blog-card-page">
        <div className="container">
          <div className="row g-4 g-md-5 justify-content-center">{BlogCardsItems}</div>
        </div>
      </div>

      <Divider />

      <BottomRibbon title="Sign up now to get <free 10k requests> per month." href="https://app.ipfindable.io/auth/signup" btnText="Sign up ->" />

      <Footer footerLogo="assets/img/core-img/ipfindable-io-logo-white-250x90.webp" footerStyle="footer-2" />
    </>
  );
}
