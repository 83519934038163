import { useState } from 'react';
import SinglePriceTable from './SinglePricePlanOne';

export default function PricingTableOne() {
  const [plan, setPlan] = useState('monthly-plan');

  const monthlyPlan = () => {
    setPlan('monthly-plan');
  };
  const yearlyPlan = () => {
    setPlan('yearly-plan');
  };

  return (
    <div className="pricing-plan-area bg-gray pb-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* Pricing Table Switcher */}
            <div className="pricing-table-switch mb-80 text-center">
              <button className="btn btn-warning btn-sm me-3" onClick={monthlyPlan}>
                Monthly
              </button>
              <button className="btn btn-warning btn-sm position-relative" onClick={yearlyPlan}>
                Yearly
                <span className="badge bg-danger rounded position-absolute start-100 translate-middle">2 Months Free</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row g-0 justify-content-center">
          {/* Single Price Table */}
          <SinglePriceTable
            planSwitch={plan}
            activePlan=""
            planTitle="Lite"
            monthlyPrice="Free"
            monthlyPriceText="Forever"
            yearlyPrice="Free"
            yearlyPriceText="Forever"
            pricingTagLine="10k requests"
            pricingTagLineHover="10,000 requests per month"
            pricingDesc={[
              { text: 'All features', availability: 'yes' },
              { text: 'HTTPS API', availability: 'yes' },
              { text: 'Batch Query', availability: 'yes' },
              { text: 'IP Geolocation', availability: 'yes' },
              { text: 'Timezone', availability: 'yes' },
              { text: 'Connection', availability: 'yes' },
              { text: 'Currency', availability: 'yes' },
              { text: 'Threat', availability: 'yes' },
              { text: 'Live Exchange', availability: 'yes', upcoming: true },
              { text: 'Threat Intelligence', availability: 'yes', upcoming: true },
              { text: 'Commercial Use', availability: 'no' },
              { text: 'Free Integration Support', availability: 'no' }
            ]}
            btnStyle="primary"
            btnLink="/pricing"
            btnText="Sign up ->"
          />

          {/* Single Price Table */}
          <SinglePriceTable
            planSwitch={plan}
            activePlan=""
            planTitle="Seed"
            monthlyPrice="$9"
            monthlyPriceInr="₹720"
            monthlyPriceText="per month"
            yearlyPrice="$90"
            yearlyPriceInr="₹7,200"
            yearlyPriceText="per year"
            pricingTagLine="500k requests"
            pricingTagLineHover="500,000 requests per month"
            pricingDesc={[
              { text: 'All features', availability: 'yes' },
              { text: 'HTTPS API', availability: 'yes' },
              { text: 'Batch Query', availability: 'yes' },
              { text: 'IP Geolocation', availability: 'yes' },
              { text: 'Timezone', availability: 'yes' },
              { text: 'Connection', availability: 'yes' },
              { text: 'Currency', availability: 'yes' },
              { text: 'Threat', availability: 'yes' },
              { text: 'Live Exchange', availability: 'yes', upcoming: true },
              { text: 'Threat Intelligence', availability: 'yes', upcoming: true },
              { text: 'Commercial Use', availability: 'yes' },
              { text: 'Free Integration Support', availability: 'yes' }
            ]}
            btnStyle="primary"
            btnLink="/pricing"
            btnText="Sign up ->"
          />

          {/* Single Price Table */}
          <SinglePriceTable
            planSwitch={plan}
            activePlan="active"
            planTitle="Startup"
            monthlyPrice="$15"
            monthlyPriceInr="₹1,200"
            monthlyPriceText="per month"
            yearlyPrice="$150"
            yearlyPriceInr="₹12,000"
            yearlyPriceText="per year"
            pricingTagLine="1M requests"
            pricingTagLineHover="1,000,000 requests per month"
            pricingDesc={[
              { text: 'All features', availability: 'yes' },
              { text: 'HTTPS API', availability: 'yes' },
              { text: 'Batch Query', availability: 'yes' },
              { text: 'IP Geolocation', availability: 'yes' },
              { text: 'Timezone', availability: 'yes' },
              { text: 'Connection', availability: 'yes' },
              { text: 'Currency', availability: 'yes' },
              { text: 'Threat', availability: 'yes' },
              { text: 'Live Exchange', availability: 'yes', upcoming: true },
              { text: 'Threat Intelligence', availability: 'yes', upcoming: true },
              { text: 'Commercial Use', availability: 'yes' },
              { text: 'Free Integration Support', availability: 'yes' }
            ]}
            btnStyle="danger"
            btnLink="/pricing"
            btnText="Sign up ->"
          />

          {/* Single Price Table */}
          <SinglePriceTable
            planSwitch={plan}
            activePlan=""
            planTitle="Business"
            monthlyPrice="$69"
            monthlyPriceInr="₹5,520"
            monthlyPriceText="per month"
            yearlyPrice="$690"
            yearlyPriceInr="₹55,200"
            yearlyPriceText="per year"
            pricingTagLine="10M requests"
            pricingTagLineHover="10,000,000 requests per month"
            pricingDesc={[
              { text: 'All features', availability: 'yes' },
              { text: 'HTTPS API', availability: 'yes' },
              { text: 'Batch Query', availability: 'yes' },
              { text: 'IP Geolocation', availability: 'yes' },
              { text: 'Timezone', availability: 'yes' },
              { text: 'Connection', availability: 'yes' },
              { text: 'Currency', availability: 'yes' },
              { text: 'Threat', availability: 'yes' },
              { text: 'Live Exchange', availability: 'yes', upcoming: true },
              { text: 'Threat Intelligence', availability: 'yes', upcoming: true },
              { text: 'Commercial Use', availability: 'yes' },
              { text: 'Free Integration Support', availability: 'yes' }
            ]}
            btnStyle="primary"
            btnLink="/pricing"
            btnText="Sign up ->"
          />
        </div>
      </div>
    </div>
  );
}
