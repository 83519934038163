import Header from '../components/navbar/Navbar';
import BreadcrumbTwo from '../components/breadcrumb/BreadcrumbTwo';
import Footer from '../components/footer/Footer';
import Divider from '../components/common/Divider';
import SEO from '../components/common/SEO';

export default function ContactUs() {
  return (
    <div>
      <SEO title="Contact Us" canonicalPath={'/contact-us'} />

      <Header brandLogo="assets/img/core-img/ipfindable-io-logo-transparent-250x90.webp" headerStyle="header-2" />

      <BreadcrumbTwo breadcrumbTitle="Contact Us" homePageUrl="/" homePageText="Home" />

      <Divider />
      <div className="container">
        <h1 className="mb-3">Let&#39;s talk about things you need!</h1>
        <br />
        <div className="row">
          <div className="col-5 col-lg-5 col-xl-5">
            <div className="mb-4 mb-md-0">
              <p className="mb-4">
                Write to us at <a href="mailto:support@ipfindable.io">support@ipfindable.io</a>
                <br />
                or
                <br />
                <a href="javascript:void(Tawk_API.toggle())"> Click here to chat</a> with us now.
                <br />
                <br />
                We will reply to you as soon as possible.
                <br />
                Yes, its reply from a real human. 😄
              </p>
            </div>
          </div>
          <div className="col-5 col-lg-5 col-xl-5">
            <div className="contact-side-info mb-4 mb-md-0">
              <div className="contact-mini-card-wrapper">
                <div className="contact-mini-card">
                  <p>Email: support@ipfindable.io</p>
                </div>
                <div className="contact-mini-card">
                  <p>
                    Address:
                    <br />
                    <b>Cubepost Services - IPFindable.io</b>
                    <br />
                    #1934 - 301, 18th Main 16th A Cross,
                    <br />
                    AECS Layout A Block, Singasandra,
                    <br />
                    Bangalore - 560068
                    <br />
                    Karnataka, India
                  </p>
                  <p>Contact: +91 81222 60623</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <Footer footerLogo="assets/img/core-img/ipfindable-io-logo-white-250x90.webp" footerStyle="footer-2" />
    </div>
  );
}
