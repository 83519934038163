/* eslint-disable react/jsx-no-target-blank */
import Header from '../components/navbar/Navbar';
import BreadcrumbTwo from '../components/breadcrumb/BreadcrumbTwo';
import Footer from '../components/footer/Footer';
import Divider from '../components/common/Divider';
import SEO from '../components/common/SEO';

export default function RefundPolicyPage() {
  return (
    <div>
      <SEO title="Refund and Cancellation Policy" canonicalPath={'/refund-and-cancellation-policy'} />

      <Header brandLogo="assets/img/core-img/ipfindable-io-logo-transparent-250x90.webp" headerStyle="header-2" />

      <BreadcrumbTwo breadcrumbTitle="Refund and Cancellation Policy" homePageUrl="/" homePageText="Home" />

      <Divider />
      <div className="faq--area">
        <div className="container">
          <div className="p-20">
            <br />
            <h1>Return and Refund Policy</h1>
            <p>Last updated: Aug 08, 2022</p>
            <p>Thank you for using our product ipfindable.io.</p>
            <p>If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns.</p>
            <p>The following terms are applicable for the ipfindale.io saas subscription you purchased with us.</p>
            <br />
            <h2>Definitions</h2>
            <p>For the purposes of this Return and Refund Policy:</p>
            <ul>
              <li>
                <p>
                  <strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
                  refers to ipfindable.io.
                </p>
              </li>
              <li>
                <p>
                  <strong>Service</strong> refers to the Website.
                </p>
              </li>
              <li>
                <p>
                  <strong>Website</strong> refers to ipfindable.io, accessible from{' '}
                  <a href="https://ipfindable.io" target="_blank">
                    https://ipfindable.io
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </p>
              </li>
            </ul>
            <br />
            <br />
            <h1>Your Service Cancellation Rights</h1>
            <p>You are entitled to cancel Your subscription without giving any reason for doing so.</p>
            <p>In order to exercise Your right of cancellation, You can choose to cancel the subscription in our app dashboad.</p>
            <ul>
              <li>
                <p>
                  By visiting this page on our website:{' '}
                  <a href="https://app.ipfindable.io/billing" target="_blank">
                    https://app.ipfindable.io/billing
                  </a>
                </p>
              </li>
            </ul>
            <br />
            <br />
            <h1>Refunds</h1>
            <p>We dont refund once payment is done unless we go through case by case which is to be informed within 7 days of purchasing the service. </p>
            <p>You can cancel your subscription any time, From which the next recurring payment will not be charged.</p>
            <br />
            <br />
            <h2>Contact Us</h2>
            <p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
            <ul>
              <li>
                <p>
                  By email: <b>support@ipfindable.io</b>
                </p>
              </li>
              <li>
                <p>
                  By clicking the chat icon on bottom right our any page{' '}
                  <a href="https://app.ipfindable.io" target="_blank">
                    https://app.ipfindable.io
                  </a>
                </p>
              </li>
            </ul>
          </div>{' '}
        </div>{' '}
      </div>
      <Divider />

      <Footer footerLogo="assets/img/core-img/ipfindable-io-logo-white-250x90.webp" footerStyle="footer-2" />
    </div>
  );
}
