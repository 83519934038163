import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export default function FooterWidgetCompany(props) {
  const { heading, address, social, size } = props;

  const socialListArr = social;
  const socialItem = socialListArr.map((social, index) => (
    <OverlayTrigger
      key={index}
      placement={social.tootipPosition}
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id={`tooltip-${social.title}`}>{social.title}</Tooltip>}
    >
      <a href={social.url} target="_blank" rel="noreferrer">
        <i className={`bi ${social.iconName}`} />
      </a>
    </OverlayTrigger>
  ));

  return (
    <div className={`col-12 col-sm-6 col-lg-${size}`}>
      <div className="footer-widget-area">
        <p style={{ borderBottom: '1px dotted grey', display: 'inline-block' }}>{heading}</p>
        <p className="mb-0">{address}</p>
        <a href="/contact-us">
          <p className="mb-0 contact-us-text">Contact Us</p>
        </a>
        <div className="footer-social-icon d-flex align-items-center mt-3">{socialItem}</div>
      </div>
    </div>
  );
}
