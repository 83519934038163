export default function FooterBottomline() {
  return (
    <div className="container">
      <div className="mt-5 rounded d-lg-flex align-items-lg-center justify-content-lg-between">
        {/* Copywrite Text */}
        <div className="text-center text-lg-start mb-3 mb-lg-0 me-lg-4">
          <small className="mb-0 text-center">
            {new Date().getFullYear()} © All rights reserved by <b>Cubepost Services</b> and <b>IPFindable.io</b>
          </small>
        </div>
      </div>
    </div>
  );
}
