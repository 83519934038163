import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BlogSidebar from './BlogSidebar';
import SEO from '../common/SEO';

export default function BlogDetailsContent({ data }) {
  const BlogPage = require(`../../data/BlogPages/${data.page || 'Blog_1'}`).default;

  return (
    <div className="blog-details row justify-content-center justify-content-md-between">
      <SEO title={data.title} canonicalPath={`/blog/${data.path}`} />

      {/* Blog Sidebar */}
      <div className="col-12 col-sm-8 col-md-4 col-lg-4 mb-5">
        <BlogSidebar />
      </div>

      <div className="col-12 col-md-8 col-lg-8 mb-5">
        <div className="text-center">
          <LazyLoadImage className="rounded tc mb-4 mb-lg-5 w-70" src={`${process.env.PUBLIC_URL}/${data.image}`} alt={data.title} />
        </div>

        <div className="post-date mb-2">{data.date}</div>
        <h1 className="mb-3">{data.title}</h1>
        <BlogPage />

        <div className="post-tag-share-button d-sm-flex align-items-center justify-content-between my-5">
          {/* Post Tag */}
          <div className="post-tag pt-3">
            <ul className="d-flex align-items-center ps-0 list-unstyled mb-0">
              {data.tags.map((ele, index) => {
                return (
                  <li key={index}>
                    <Link className="btn btn-info btn-sm me-2 rounded-pill" to="#">
                      {ele}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
