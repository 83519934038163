import FooterWidgetLogo from './FooterWidgetLogo';
import FooterWidgetNav from './FooterWidgetNav';
import FooterWidgetCompany from './FooterWidgetCompany';
import FooterBottomline from './FooterBottomline';

export default function Footer(props) {
  const { footerLogo, footerStyle } = props;

  return (
    <>
      <footer className={`footer-area ${footerStyle} pt-80 pb-10`}>
        <div className="container">
          <div className="row g-4 g-lg-5">
            <FooterWidgetLogo footerLogo={footerLogo} footerText="Crafted by certified AWS Architects for scale and security." />
            {/* TODO: Fix links */}
            <FooterWidgetNav
              size="3"
              heading="IPFindable.io"
              footerNav={[
                {
                  title: 'IP Geolocation API',
                  link: 'https://app.ipfindable.io/auth/login'
                },
                {
                  title: 'Login',
                  link: 'https://app.ipfindable.io/auth/login'
                },
                {
                  title: 'Free Sign up',
                  link: 'https://app.ipfindable.io/auth/signup'
                }
              ]}
            />

            <FooterWidgetNav
              size="3"
              heading="Resources"
              footerNav={[
                {
                  title: 'Documentation',
                  url: '#documentation',
                  soon: true
                },
                {
                  title: 'Blog',
                  url: '/blog'
                },
                {
                  title: 'Status Page',
                  url: '#status-page',
                  soon: true
                },
                {
                  title: 'Pricing',
                  url: '/pricing'
                },
                {
                  title: 'FAQ',
                  url: '/faq'
                },
                {
                  title: 'Terms & Conditions',
                  url: '/terms-and-conditions'
                },
                {
                  title: 'Privacy Policy',
                  url: '/terms-and-conditions#privacy-policy'
                },
                {
                  title: 'Refund and Cancellation Policy',
                  url: '/refund-and-cancellation-policy'
                }
              ]}
            />
            {/* TODO: Add social urls */}
            <FooterWidgetCompany
              size="3"
              heading="Company"
              address="Cubepost Services - IN"
              social={[
                {
                  url: 'https://github.com',
                  tootipPosition: 'top',
                  title: 'Github',
                  iconName: 'bi-github'
                },
                {
                  url: 'https://facebook.com',
                  tootipPosition: 'top',
                  title: 'Facebook',
                  iconName: 'bi-facebook'
                },
                {
                  url: 'https://twitter.com/IPFindable_io',
                  tootipPosition: 'top',
                  title: 'Twitter',
                  iconName: 'bi-twitter'
                }
              ]}
            />
          </div>
        </div>

        <FooterBottomline />
      </footer>
    </>
  );
}
