import React from 'react';

export default function BlogPage() {
  return (
    <>
      <div>
        <p>Ensure your users have the rights to your service for compliance and security purposes.</p>
        <p>
          Geo-blocking is a practice done by many companies serving multiple geographies for various reaons. geo-blocking has drawn the ire of the public, and
          certain governments took steps to crack down on it.
        </p>
        <br />
        <p>
          Example, The EU implemented the Digital Single Market Strategy to end restrictions based on geography amongst member nations. The act was followed by
          the <span className="text-primary">Geo Blocking Regulation in 2018 to ban certain techniques of geo-blocking in the EU</span>, including rerouting
          without user consent. However, while the EU is not in favour, many countries have no problem with geo-blocking, and many businesses still block users
          based on a region. We explore some of the reasons behind the blocking to shed light on the pros and cons of geo-blocking..
          <br />
        </p>
        <br />
        <b className="text-primary">
          Blocking the user by country or region can be detected by both frontend and backend using the <a href="https://ipfindable.io">IPFindable.io</a>{' '}
          service. Sign up now for free.
        </b>
        <br />
      </div>
    </>
  );
}
