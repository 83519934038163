export default function PricingHeading() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-10 col-md-9 col-lg-8 col-xxl-8">
          <div className="section-heading text-center">
            <h6 className="text-dark">Pricing Plan</h6>
            <h3>Simple pricing plan</h3>
            <p className="text-dark">How we are able to provide at low cost ? We run services at scale.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
