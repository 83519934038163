import Header from '../components/navbar/Navbar';
import BreadcrumbTwo from '../components/breadcrumb/BreadcrumbTwo';
import Footer from '../components/footer/Footer';
import Divider from '../components/common/Divider';
import Accordion from 'react-bootstrap/Accordion';
import SEO from '../components/common/SEO';

export default function FaqPage() {
  return (
    <div>
      <SEO title="FAQ" canonicalPath={'/faq'} />

      <Header brandLogo="assets/img/core-img/ipfindable-io-logo-transparent-250x90.webp" headerStyle="header-2" />

      <BreadcrumbTwo breadcrumbTitle="FAQ's" homePageUrl="/" homePageText="Home" currentPageText="FAQ" />

      <Divider />

      <div className="faq--area">
        <div className="container">
          <div className="row g-4 g-lg-5">
            <div className="col-12 col-lg-6">
              <Accordion className="faq--accordian" defaultActiveKey="0">
                <Accordion.Item className="my-1" eventKey="0">
                  <Accordion.Header className="card border-0">1. How can I purchased IPFindable.io?</Accordion.Header>
                  <Accordion.Body>Its a SaaS based monthly or yearly subscription service. Which can be paid using credit or debit card.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item className="my-1" eventKey="1">
                  <Accordion.Header className="card border-0">2. How accurate is IP Geolocation?</Accordion.Header>
                  <Accordion.Body>
                    Our database is accurate in country level and better at city level. The accuracy for mobile networks will vary depending on the mobile
                    carrier.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item className="my-1" eventKey="2">
                  <Accordion.Header className="card border-0">3. How fresh is the data ?</Accordion.Header>
                  <Accordion.Body>
                    We update our systems in automated CICD model frequently, Which is considered the best and fast without having any downtime and hot
                    swappable data.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item className="my-1" eventKey="3">
                  <Accordion.Header className="card border-0">3. Do credits get carry forwared to next month ?</Accordion.Header>
                  <Accordion.Body>No, Credits do not get carry forwared.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item className="my-1" eventKey="4">
                  <Accordion.Header className="card border-0">3. What is the billing cycle ?</Accordion.Header>
                  <Accordion.Body>
                    The billing cycle will be monthly or yearly based on your selected plan. The requests quota will be renewed monthlty based on the plan.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="my-1" eventKey="5">
                  <Accordion.Header className="card border-0">8. What will happen if quota exceeds.?</Accordion.Header>
                  <Accordion.Body>
                    You will get <code>403 quota excceed</code> error. We allow marginal exceeding in quota by design. You can also upgrade for more usase
                    quota.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className="col-12 col-lg-6">
              <Accordion className="faq--accordian" defaultActiveKey="11">
                <Accordion.Item className="my-1" eventKey="11">
                  <Accordion.Header className="card border-0">5. What is your SLA?</Accordion.Header>
                  <Accordion.Body>We provide 99.9% uptime SLA on all of our paid plans.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="my-1" eventKey="5">
                  <Accordion.Header className="card border-0">8. Can I use free plan for commercial purposes.?</Accordion.Header>
                  <Accordion.Body>
                    No. We do not allow commercial use of our free or Lite plan. Free or Lite plan is only for development and evaluation purpose. You can
                    upgrade to any paid plan for commercial use.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item className="my-1" eventKey="12">
                  <Accordion.Header className="card border-0">6. Do you have any discount for non-profit or educational institutes?</Accordion.Header>
                  <Accordion.Body>Yes, we do. You can contact us using the chat.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item className="my-1" eventKey="13">
                  <Accordion.Header className="card border-0">7. Do you provide customized service and plans for enterprises?</Accordion.Header>
                  <Accordion.Body>
                    Yes, The plans can be customers for large volume and can also have custome functions build for the organization.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item className="my-1" eventKey="14">
                  <Accordion.Header className="card border-0">4. Do you monitor the user or track the users IP addresses ?</Accordion.Header>
                  <Accordion.Body>
                    No we do not track the user IP addresses. The API anbalytics is based on the api keys and users and not the IP addresses.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item className="my-1" eventKey="15">
                  <Accordion.Header className="card border-0">8. Are you GDPR & CCPA compliant?</Accordion.Header>
                  <Accordion.Body>Yes, we are compliant with the regulations.</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      <Divider />

      <Footer footerLogo="assets/img/core-img/ipfindable-io-logo-white-250x90.webp" footerStyle="footer-2" />
    </div>
  );
}
