import { useParams } from 'react-router-dom';
import Header from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import Divider from '../components/common/Divider';
import BreadcrumbThree from '../components/breadcrumb/BreadcrumbThree';
import BlogDetailsContent from '../components/blog/BlogDetailsContent';
import BottomRibbon from '../components/common/BottomRibbon';
import BlogData from '../data/BlogPages/index';

export default function BlogDetailsPage() {
  const parameter = useParams();
  const data = BlogData.filter((blog) => blog.path === parameter.postId);

  return (
    <div>
      <Header brandLogo="assets/img/core-img/ipfindable-io-logo-transparent-250x90.webp" headerStyle="header-2" />

      <BreadcrumbThree breadcrumbTitle="Blog" homePageUrl="/" homePageText="Home" secondPageText="Blog" secondPageUrl="/blog" currentPageText="article" />

      <Divider />

      <div className="blog-area">
        <div className="container">
          <BlogDetailsContent data={data[0]} />
        </div>
      </div>

      <Divider />

      <BottomRibbon title="Sign up now to get <free 10k requests> per month." href="https://app.ipfindable.io/auth/signup" btnText="Sign up ->" />

      <Footer footerLogo="assets/img/core-img/ipfindable-io-logo-white-250x90.webp" footerStyle="footer-2" />
    </div>
  );
}
