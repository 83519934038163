import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function SEO(props) {
  const { title, canonicalPath } = props;

  return (
    <HelmetProvider>
      <Helmet>
        <title>{`IPFindable.io - ${title}`}</title>
        <link rel="canonical" href={`https://ipfindable.io${canonicalPath || ''}`} />
      </Helmet>
    </HelmetProvider>
  );
}
