import { Link } from 'react-router-dom';

export default function NavbarHeading() {
  return (
    <div className="navbar-nav navbar-nav-scroll" style={{ marginTop: 40 }}>
      <div className="nav-item">
        <Link className="nav-link" to="/#features">
          Features
        </Link>
      </div>
      <div className="nav-item">
        <Link className="nav-link" to="/pricing">
          Pricing
        </Link>
      </div>
      <div className="nav-item">
        {/* TODO: Fix Links */}
        <Link className="nav-link text-center" to="#">
          Documentation <br />
          <small className="badge text-white bg-success rounded fz-8">coming soon</small>
        </Link>
      </div>
      <div className="nav-item">
        <Link className="nav-link" to="/blog">
          Blog
        </Link>
      </div>
    </div>
  );
}
