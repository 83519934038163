import { RedocStandalone } from 'redoc';
import yaml from 'js-yaml';

import Divider from '../components/common/Divider';
import Header from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import SEO from '../components/common/SEO';

const yamlStr = `
openapi: 3.0.0
servers:
  - url: //petstore.swagger.io/v2
    description: Default server
  - url: //petstore.swagger.io/sandbox
    description: Sandbox server
info:
  description: |
    This is a sample server Petstore server.
    You can find out more about Swagger at
    [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/).
    For this sample, you can use the api key "special-key" to test the authorization filters.

    # Introduction
    This API is documented in **OpenAPI format** and is based on
    [Petstore sample](http://petstore.swagger.io/) provided by [swagger.io](http://swagger.io) team.
    It was **extended** to illustrate features of [generator-openapi-repo](https://github.com/Rebilly/generator-openapi-repo)
    tool and [ReDoc](https://github.com/Redocly/redoc) documentation. In addition to standard
    OpenAPI syntax we use a few [vendor extensions](https://github.com/Redocly/redoc/blob/master/docs/redoc-vendor-extensions.md).


    # API Endpoint
    - Global Server: \`\`\`https://api.ipfindable.io\`\`\`
    - EU Server: \`\`\`https://eu-api.ipfindable.io\`\`\`

    ##### Example Request
    \`\`\`
    curl "https://api.ipdata.co/24.24.24.24?api-key=test&fields=ip,is_eu,city,region,region_code,country_name,country_code,continent_name,continent_code,latitude,longitude,postal,calling_code,flag,emoji_flag,emoji_unicode"
    \`\`\`

    ##### Sample Response
    \`\`\`
    {
      "ip": "24.24.24.24",
      "is_eu": false,
      "city": "Syracuse",
      "region": "New York",
      "region_code": "NY",
      "country_name": "United States",
      "country_code": "US",
      "continent_name": "North America",
      "continent_code": "NA",
      "latitude": 43.0483,
      "longitude": -76.1468,
      "postal": "13261",
      "calling_code": "1",
      "flag": "https://ipdata.co/flags/us.png",
      "emoji_flag": "🇺🇸",
      "emoji_unicode": "U+1F1FA U+1F1F8",
    }
    \`\`\`


    # OpenAPI Specification
    This API is documented in **OpenAPI format** and is based on
    [Petstore sample](http://petstore.swagger.io/) provided by [swagger.io](http://swagger.io) team.
    It was **extended** to illustrate features of [generator-openapi-repo](https://github.com/Rebilly/generator-openapi-repo)
    tool and [ReDoc](https://github.com/Redocly/redoc) documentation. In addition to standard
    OpenAPI syntax we use a few [vendor extensions](https://github.com/Redocly/redoc/blob/master/docs/redoc-vendor-extensions.md).

    # Authentication

    Petstore offers two forms of authentication:
      - API Key
      - OAuth2
    OAuth2 - an open protocol to allow secure authorization in a simple
    and standard method from web, mobile and desktop applications.

    <SecurityDefinitions />

  version: 1.0.0
  title: Swagger Petstore
  termsOfService: 'http://swagger.io/terms/'
  contact:
    name: API Support
    email: apiteam@swagger.io
    url: https://github.com/Redocly/redoc
  x-logo:
    url: 'https://redocly.github.io/redoc/petstore-logo.png'
    altText: Petstore logo
  license:
    name: Apache 2.0
    url: 'http://www.apache.org/licenses/LICENSE-2.0.html'
externalDocs:
  description: Find out how to create Github repo for your OpenAPI spec.
  url: 'https://github.com/Rebilly/generator-openapi-repo'
tags:
  - name: pet
    description: Everything about your Pets
  - name: store
    description: Access to Petstore orders
  - name: user
    description: Operations about user
  - name: pet_model
    x-displayName: The Pet Model
    description: |
      <SchemaDefinition schemaRef="#/components/schemas/Pet" />
  - name: store_model
    x-displayName: The Order Model
    description: |
      <SchemaDefinition schemaRef="#/components/schemas/Order" exampleRef="#/components/examples/Order" showReadOnly={true} showWriteOnly={true} />
x-tagGroups:
  - name: General
    tags:
      - pet
      - store
  - name: User Management
    tags:
      - user
  - name: Models
    tags:
      - pet_model
      - store_model
paths:
  /pet:
    parameters:
      - name: Accept-Language
        in: header
        description: "The language you prefer for messages. Supported values are en-AU, en-CA, en-GB, en-US"
        example: en-US
        required: false
        schema:
          type: string
          default: en-AU
      - name: cookieParam
        in: cookie
        description: Some cookie
        required: true
        schema:
          type: integer
          format: int64
    post:
      tags:
        - pet
      summary: Add a new pet to the store
      description: |
        This is a sample server Petstore server.
        You can find out more about Swagger at
        [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/).
        For this sample, you can use the api key "special-key" to test the authorization filters.
    
        # Introduction
        This API is documented in **OpenAPI format** and is based on
        [Petstore sample](http://petstore.swagger.io/) provided by [swagger.io](http://swagger.io) team.
        It was **extended** to illustrate features of [generator-openapi-repo](https://github.com/Rebilly/generator-openapi-repo)
        tool and [ReDoc](https://github.com/Redocly/redoc) documentation. In addition to standard
        OpenAPI syntax we use a few [vendor extensions](https://github.com/Redocly/redoc/blob/master/docs/redoc-vendor-extensions.md).
    
    
        # API Endpoint
        - Global Server: \`\`\`https://api.ipfindable.io\`\`\`
        - EU Server: \`\`\`https://eu-api.ipfindable.io\`\`\`
    
        ##### Example Request
        \`\`\`
        curl "https://api.ipdata.co/24.24.24.24?api-key=test&fields=ip,is_eu,city,region,region_code,country_name,country_code,continent_name,continent_code,latitude,longitude,postal,calling_code,flag,emoji_flag,emoji_unicode"
        \`\`\`
    
        ##### Sample Response
        \`\`\`
        {
          "ip": "24.24.24.24",
          "is_eu": false,
          "city": "Syracuse",
          "region": "New York",
          "region_code": "NY",
          "country_name": "United States",
          "country_code": "US",
          "continent_name": "North America",
          "continent_code": "NA",
          "latitude": 43.0483,
          "longitude": -76.1468,
          "postal": "13261",
          "calling_code": "1",
          "flag": "https://ipdata.co/flags/us.png",
          "emoji_flag": "🇺🇸",
          "emoji_unicode": "U+1F1FA U+1F1F8",
        }
        \`\`\`
      operationId: addPet
      responses:
        '405':
          description: Invalid input
      security:
        - petstore_auth:
            - 'write:pets'
            - 'read:pets'
      x-codeSamples:
        - lang: 'C#'
          source: |
            PetStore.v1.Pet pet = new PetStore.v1.Pet();
            pet.setApiKey("your api key");
            pet.petType = PetStore.v1.Pet.TYPE_DOG;
            pet.name = "Rex";
            // set other fields
            PetStoreResponse response = pet.create();
            if (response.statusCode == HttpStatusCode.Created)
            {
              // Successfully created
            }
            else
            {
              // Something wrong -- check response for errors
              Console.WriteLine(response.getRawResponse());
            }
        - lang: PHP
          source: |
            $form = new PetStoreEntitiesPet();
            $form->setPetType("Dog");
            $form->setName("Rex");
            // set other fields
            try {
                $pet = $client->pets()->create($form);
            } catch (UnprocessableEntityException $e) {
                var_dump($e->getErrors());
            }
      requestBody:
        $ref: '#/components/requestBodies/Pet'
    put:
      tags:
        - pet
      summary: Update an existing pet
      description: ''
      operationId: updatePet
      responses:
        '400':
          description: Invalid ID supplied
        '404':
          description: Pet not found
        '405':
          description: Validation exception
      security:
        - petstore_auth:
            - 'write:pets'
            - 'read:pets'
      x-codeSamples:
        - lang: PHP
          source: |
            $form = new PetStoreEntitiesPet();
            $form->setPetId(1);
            $form->setPetType("Dog");
            $form->setName("Rex");
            // set other fields
            try {
                $pet = $client->pets()->update($form);
            } catch (UnprocessableEntityException $e) {
                var_dump($e->getErrors());
            }
      requestBody:
        $ref: '#/components/requestBodies/Pet'
  '/pet/{petId}':
    get:
      tags:
        - pet
      summary: Find pet by ID
      description: Returns a single pet
      operationId: getPetById
      parameters:
        - name: petId
          in: path
          description: ID of pet to return
          required: true
          deprecated: true
          schema:
            type: integer
            format: int64
      responses:
        '200':
          description: successful operation
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/Pet'
            application/xml:
              schema:
                $ref: '#/components/schemas/Pet'
        '400':
          description: Invalid ID supplied
        '404':
          description: Pet not found
      security:
        - api_key: []
    post:
      tags:
        - pet
      summary: Updates a pet in the store with form data
      description: ''
      operationId: updatePetWithForm
      parameters:
        - name: petId
          in: path
          description: ID of pet that needs to be updated
          required: true
          schema:
            type: integer
            format: int64
      responses:
        '405':
          description: Invalid input
      security:
        - petstore_auth:
            - 'write:pets'
            - 'read:pets'
      requestBody:
        content:
          application/x-www-form-urlencoded:
            schema:
              type: object
              properties:
                name:
                  description: Updated name of the pet
                  type: string
                status:
                  description: Updated status of the pet
                  type: string
    delete:
      tags:
        - pet
      summary: Deletes a pet
      description: ''
      operationId: deletePet
      parameters:
        - name: api_key
          in: header
          required: false
          schema:
            type: string
          example: "Bearer <TOKEN>"
        - name: petId
          in: path
          description: Pet id to delete
          required: true
          schema:
            type: integer
            format: int64
      responses:
        '400':
          description: Invalid pet value
      security:
        - petstore_auth:
            - 'write:pets'
            - 'read:pets'
  '/pet/{petId}/uploadImage':
    post:
      tags:
        - pet
      summary: uploads an image
      description: ''
      operationId: uploadFile
      parameters:
        - name: petId
          in: path
          description: ID of pet to update
          required: true
          schema:
            type: integer
            format: int64
      responses:
        '200':
          description: successful operation
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/ApiResponse'
      security:
        - petstore_auth:
            - 'write:pets'
            - 'read:pets'
      requestBody:
        content:
          application/octet-stream:
            schema:
              type: string
              format: binary
  /pet/findByStatus:
    get:
      tags:
        - pet
      summary: Finds Pets by status
      description: Multiple status values can be provided with comma separated strings
      operationId: findPetsByStatus
      parameters:
        - name: status
          in: query
          description: Status values that need to be considered for filter
          required: true
          style: form
          schema:
            type: array
            minItems: 1
            maxItems: 3
            items:
              type: string
              enum:
                - available
                - pending
                - sold
              default: available
      responses:
        '200':
          description: successful operation
          content:
            application/json:
              schema:
                type: array
                items:
                  $ref: '#/components/schemas/Pet'
            application/xml:
              schema:
                type: array
                items:
                  $ref: '#/components/schemas/Pet'
        '400':
          description: Invalid status value
      security:
        - petstore_auth:
            - 'write:pets'
            - 'read:pets'
  /pet/findByTags:
    get:
      tags:
        - pet
      summary: Finds Pets by tags
      description: >-
        Multiple tags can be provided with comma separated strings. Use tag1,
        tag2, tag3 for testing.
      operationId: findPetsByTags
      deprecated: true
      parameters:
        - name: tags
          in: query
          description: Tags to filter by
          required: true
          style: form
          schema:
            type: array
            items:
              type: string
      responses:
        '200':
          description: successful operation
          content:
            application/json:
              schema:
                type: array
                items:
                  $ref: '#/components/schemas/Pet'
            application/xml:
              schema:
                type: array
                maxItems: 999
                items:
                  maxItems: 111
                  $ref: '#/components/schemas/Pet'
        '400':
          description: Invalid tag value
      security:
        - petstore_auth:
            - 'write:pets'
            - 'read:pets'
  /store/inventory:
    get:
      tags:
        - store
      summary: Returns pet inventories by status
      description: Returns a map of status codes to quantities
      operationId: getInventory
      responses:
        '200':
          description: successful operation
          content:
            application/json:
              schema:
                type: object
                minProperties: 2
                additionalProperties:
                  type: integer
                  format: int32
      security:
        - api_key: []
  /store/order:
    post:
      tags:
        - store
      summary: Place an order for a pet
      description: ''
      operationId: placeOrder
      responses:
        '200':
          description: successful operation
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/Order'
            application/xml:
              schema:
                $ref: '#/components/schemas/Order'
        '400':
          description: Invalid Order
          content:
            application/json:
              example:
                status: 400
                message: "Invalid Order"
      requestBody:
        content:
          application/json:
            schema:
              $ref: '#/components/schemas/Order'
        description: order placed for purchasing the pet
        required: true
  '/store/order/{orderId}':
    get:
      tags:
        - store
      summary: Find purchase order by ID
      description: >-
        For valid response try integer IDs with value <= 5 or > 10. Other values
        will generated exceptions
      operationId: getOrderById
      parameters:
        - name: orderId
          in: path
          description: ID of pet that needs to be fetched
          required: true
          schema:
            type: integer
            format: int64
            minimum: 1
            maximum: 5
      responses:
        '200':
          description: successful operation
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/Order'
            application/xml:
              schema:
                $ref: '#/components/schemas/Order'
        '400':
          description: Invalid ID supplied
        '404':
          description: Order not found
    delete:
      tags:
        - store
      summary: Delete purchase order by ID
      description: >-
        For valid response try integer IDs with value < 1000. Anything above
        1000 or nonintegers will generate API errors
      operationId: deleteOrder
      parameters:
        - name: orderId
          in: path
          description: ID of the order that needs to be deleted
          required: true
          schema:
            type: string
            minimum: 1
      responses:
        '400':
          description: Invalid ID supplied
        '404':
          description: Order not found
  /store/subscribe:
    post:
      tags:
        - store
      summary: Subscribe to the Store events
      description: Add subscription for a store events
      requestBody:
        content:
          application/json:
            schema:
              type: object
              properties:
                callbackUrl:
                  type: string
                  format: uri
                  description: This URL will be called by the server when the desired event will occur
                  example: https://myserver.com/send/callback/here
                eventName:
                  type: string
                  description: Event name for the subscription
                  enum:
                    - orderInProgress
                    - orderShipped
                    - orderDelivered
                  example: orderInProgress
              required:
                - callbackUrl
                - eventName
      responses:
        '201':
          description: Subscription added
          content:
            application/json:
              schema:
                type: object
                properties:
                  subscriptionId:
                    type: string
                    example: AAA-123-BBB-456
      callbacks:
        orderInProgress:
          '{$request.body#/callbackUrl}?event={$request.body#/eventName}':
            servers:
              - url: //callback-url.path-level/v1
                description: Path level server 1
              - url: //callback-url.path-level/v2
                description: Path level server 2
            post:
              summary: Order in Progress (Summary)
              description: A callback triggered every time an Order is updated status to "inProgress" (Description)
              externalDocs:
                description: Find out more
                url: 'https://more-details.com/demo'
              requestBody:
                content:
                  application/json:
                    schema:
                      type: object
                      properties:
                        orderId:
                          type: string
                          example: '123'
                        timestamp:
                          type: string
                          format: date-time
                          example: '2018-10-19T16:46:45Z'
                        status:
                          type: string
                          example: 'inProgress'
                  application/xml:
                    schema:
                      type: object
                      properties:
                        orderId:
                          type: string
                          example: '123'
                    example: |
                      <?xml version="1.0" encoding="UTF-8"?>
                      <root>
                        <orderId>123</orderId>
                        <status>inProgress</status>
                        <timestamp>2018-10-19T16:46:45Z</timestamp>
                      </root>
              responses:
                '200':
                  description: Callback successfully processed and no retries will be performed
                  content:
                    application/json:
                      schema:
                        type: object
                        properties:
                          someProp:
                            type: string
                            example: '123'
                '299':
                  description: Response for cancelling subscription
                '500':
                  description: Callback processing failed and retries will be performed
              x-codeSamples:
                - lang: 'C#'
                  source: |
                    PetStore.v1.Pet pet = new PetStore.v1.Pet();
                    pet.setApiKey("your api key");
                    pet.petType = PetStore.v1.Pet.TYPE_DOG;
                    pet.name = "Rex";
                    // set other fields
                    PetStoreResponse response = pet.create();
                    if (response.statusCode == HttpStatusCode.Created)
                    {
                      // Successfully created
                    }
                    else
                    {
                      // Something wrong -- check response for errors
                      Console.WriteLine(response.getRawResponse());
                    }
                - lang: PHP
                  source: |
                    $form = new PetStoreEntitiesPet();
                    $form->setPetType("Dog");
                    $form->setName("Rex");
                    // set other fields
                    try {
                        $pet = $client->pets()->create($form);
                    } catch (UnprocessableEntityException $e) {
                        var_dump($e->getErrors());
                    }
            put:
              description: Order in Progress (Only Description)
              servers:
                - url: //callback-url.operation-level/v1
                  description: Operation level server 1 (Operation override)
                - url: //callback-url.operation-level/v2
                  description: Operation level server 2 (Operation override)
              requestBody:
                content:
                  application/json:
                    schema:
                      type: object
                      properties:
                        orderId:
                          type: string
                          example: '123'
                        timestamp:
                          type: string
                          format: date-time
                          example: '2018-10-19T16:46:45Z'
                        status:
                          type: string
                          example: 'inProgress'
                  application/xml:
                    schema:
                      type: object
                      properties:
                        orderId:
                          type: string
                          example: '123'
                    example: |
                      <?xml version="1.0" encoding="UTF-8"?>
                      <root>
                        <orderId>123</orderId>
                        <status>inProgress</status>
                        <timestamp>2018-10-19T16:46:45Z</timestamp>
                      </root>
              responses:
                '200':
                  description: Callback successfully processed and no retries will be performed
                  content:
                    application/json:
                      schema:
                        type: object
                        properties:
                          someProp:
                            type: string
                            example: '123'
        orderShipped:
          '{$request.body#/callbackUrl}?event={$request.body#/eventName}':
            post:
              description: |
                Very long description
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              requestBody:
                content:
                  application/json:
                    schema:
                      type: object
                      properties:
                        orderId:
                          type: string
                          example: '123'
                        timestamp:
                          type: string
                          format: date-time
                          example: '2018-10-19T16:46:45Z'
                        estimatedDeliveryDate:
                          type: string
                          format: date-time
                          example: '2018-11-11T16:00:00Z'
              responses:
                '200':
                  description: Callback successfully processed and no retries will be performed
        orderDelivered:
          'http://notificationServer.com?url={$request.body#/callbackUrl}&event={$request.body#/eventName}':
            post:
              deprecated: true
              summary: Order delivered
              description: A callback triggered every time an Order is delivered to the recipient
              requestBody:
                content:
                  application/json:
                    schema:
                      type: object
                      properties:
                        orderId:
                          type: string
                          example: '123'
                        timestamp:
                          type: string
                          format: date-time
                          example: '2018-10-19T16:46:45Z'
              responses:
                '200':
                  description: Callback successfully processed and no retries will be performed
  /user:
    post:
      tags:
        - user
      summary: Create user
      description: This can only be done by the logged in user.
      operationId: createUser
      responses:
        default:
          description: successful operation
      requestBody:
        content:
          application/json:
            schema:
              $ref: '#/components/schemas/User'
        description: Created user object
        required: true
  '/user/{username}':
    get:
      tags:
        - user
      summary: Get user by user name
      description: ''
      operationId: getUserByName
      parameters:
        - name: username
          in: path
          description: 'The name that needs to be fetched. Use user1 for testing. '
          required: true
          schema:
            type: string
      responses:
        '200':
          description: successful operation
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/User'
            application/xml:
              schema:
                $ref: '#/components/schemas/User'
        '400':
          description: Invalid username supplied
        '404':
          description: User not found
    put:
      tags:
        - user
      summary: Updated user
      description: This can only be done by the logged in user.
      operationId: updateUser
      parameters:
        - name: username
          in: path
          description: name that need to be deleted
          required: true
          schema:
            type: string
      responses:
        '400':
          description: Invalid user supplied
        '404':
          description: User not found
      requestBody:
        content:
          application/json:
            schema:
              $ref: '#/components/schemas/User'
        description: Updated user object
        required: true
    delete:
      tags:
        - user
      summary: Delete user
      description: This can only be done by the logged in user.
      operationId: deleteUser
      parameters:
        - name: username
          in: path
          description: The name that needs to be deleted
          required: true
          schema:
            type: string
      responses:
        '400':
          description: Invalid username supplied
        '404':
          description: User not found
  /user/createWithArray:
    post:
      tags:
        - user
      summary: Creates list of users with given input array
      description: ''
      operationId: createUsersWithArrayInput
      responses:
        default:
          description: successful operation
      requestBody:
        $ref: '#/components/requestBodies/UserArray'
  /user/createWithList:
    post:
      tags:
        - user
      summary: Creates list of users with given input array
      description: ''
      operationId: createUsersWithListInput
      responses:
        default:
          description: successful operation
      requestBody:
        $ref: '#/components/requestBodies/UserArray'
  /user/login:
    get:
      tags:
        - user
      summary: Logs user into the system
      description: ''
      operationId: loginUser
      parameters:
        - name: username
          in: query
          description: The user name for login
          required: true
          schema:
            type: string
        - name: password
          in: query
          description: The password for login in clear text
          required: true
          schema:
            type: string
      responses:
        '200':
          description: successful operation
          headers:
            X-Rate-Limit:
              description: calls per hour allowed by the user
              schema:
                type: integer
                format: int32
            X-Expires-After:
              description: date in UTC when token expires
              schema:
                type: string
                format: date-time
          content:
            application/json:
              schema:
                type: string
              examples:
                response:
                  value: OK
            application/xml:
              schema:
                type: string
              examples:
                response:
                  value: <Message> OK </Message>
            text/plain:
              examples:
                response:
                  value: OK
        '400':
          description: Invalid username/password supplied
  /user/logout:
    get:
      tags:
        - user
      summary: Logs out current logged in user session
      description: ''
      operationId: logoutUser
      responses:
        default:
          description: successful operation
components:
  schemas:
    ApiResponse:
      type: object
      properties:
        code:
          type: integer
          format: int32
        type:
          type: string
        message:
          type: string
    Cat:
      description: A representation of a cat
      allOf:
        - $ref: '#/components/schemas/Pet'
        - type: object
          properties:
            huntingSkill:
              type: string
              description: The measured skill for hunting
              default: lazy
              example: adventurous
              enum:
                - clueless
                - lazy
                - adventurous
                - aggressive
          required:
            - huntingSkill
    Category:
      type: object
      properties:
        id:
          description: Category ID
          allOf:
            - $ref: '#/components/schemas/Id'
        name:
          description: Category name
          type: string
          minLength: 1
        sub:
          description: Test Sub Category
          type: object
          properties:
            prop1:
              type: string
              description: Dumb Property
      xml:
        name: Category
    Dog:
      description: A representation of a dog
      allOf:
        - $ref: '#/components/schemas/Pet'
        - type: object
          properties:
            packSize:
              type: integer
              format: int32
              description: The size of the pack the dog is from
              default: 1
              minimum: 1
          required:
            - packSize
    HoneyBee:
      description: A representation of a honey bee
      allOf:
        - $ref: '#/components/schemas/Pet'
        - type: object
          properties:
            honeyPerDay:
              type: number
              description: Average amount of honey produced per day in ounces
              example: 3.14
              multipleOf: .01
          required:
            - honeyPerDay
    Id:
      type: integer
      format: int64
      readOnly: true
    Order:
      type: object
      properties:
        id:
          description: Order ID
          allOf:
            - $ref: '#/components/schemas/Id'
        petId:
          description: Pet ID
          allOf:
            - $ref: '#/components/schemas/Id'
        quantity:
          type: integer
          format: int32
          minimum: 1
          default: 1
        shipDate:
          description: Estimated ship date
          type: string
          format: date-time
        status:
          type: string
          description: Order Status
          enum:
            - placed
            - approved
            - delivered
        complete:
          description: Indicates whenever order was completed or not
          type: boolean
          default: false
          readOnly: true
        requestId:
          description: Unique Request Id
          type: string
          writeOnly: true
      xml:
        name: Order
    Pet:
      type: object
      required:
        - name
        - photoUrls
      discriminator:
        propertyName: petType
        mapping:
          cat: '#/components/schemas/Cat'
          dog: '#/components/schemas/Dog'
          bee: '#/components/schemas/HoneyBee'
      properties:
        id:
          externalDocs:
            description: "Find more info here"
            url: "https://example.com"
          description: Pet ID
          allOf:
            - $ref: '#/components/schemas/Id'
        category:
          description: Categories this pet belongs to
          allOf:
            - $ref: '#/components/schemas/Category'
        name:
          description: The name given to a pet
          type: string
          example: Guru
        photoUrls:
          description: The list of URL to a cute photos featuring pet
          type: array
          maxItems: 20
          xml:
            name: photoUrl
            wrapped: true
          items:
            type: string
            format: url
        friend:
          allOf:
            - $ref: '#/components/schemas/Pet'
        tags:
          description: Tags attached to the pet
          type: array
          minItems: 1
          xml:
            name: tag
            wrapped: true
          items:
            $ref: '#/components/schemas/Tag'
        status:
          type: string
          description: Pet status in the store
          enum:
            - available
            - pending
            - sold
        petType:
          description: Type of a pet
          type: string
      xml:
        name: Pet
    Tag:
      type: object
      properties:
        id:
          description: Tag ID
          allOf:
            - $ref: '#/components/schemas/Id'
        name:
          description: Tag name
          type: string
          minLength: 1
      xml:
        name: Tag
    User:
      type: object
      properties:
        id:
          $ref: '#/components/schemas/Id'
        pet:
          oneOf:
            - $ref: '#/components/schemas/Pet'
            - $ref: '#/components/schemas/Tag'
        username:
          description: User supplied username
          type: string
          minLength: 4
          example: John78
        firstName:
          description: User first name
          type: string
          minLength: 1
          example: John
        lastName:
          description: User last name
          type: string
          minLength: 1
          example: Smith
        email:
          description: User email address
          type: string
          format: email
          example: john.smith@example.com
        password:
          type: string
          description: >-
            User password, MUST contain a mix of upper and lower case letters,
            as well as digits
          format: password
          minLength: 8
          pattern: '/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/'
          example: drowssaP123
        phone:
          description: User phone number in international format
          type: string
          pattern: '/^+(?:[0-9]-?){6,14}[0-9]$/'
          example: +1-202-555-0192
        userStatus:
          description: User status
          type: integer
          format: int32
        addresses:  
          type: array
          minItems: 0
          maxLength: 10
          items:
            - type: object
              properties:
                city:
                  type: string
                  minLength: 0
                country:
                  type: string
                  minLength: 0
                street:
                  description: includes build/apartment number
                  type: string
                  minLength: 0
            - type: number
          additionalItems:
            type: string
      xml:
        name: User
  requestBodies:
    Pet:
      content:
        application/json:
          schema:
            allOf:
              - description: My Pet
                title: Pettie
              - $ref: '#/components/schemas/Pet'
        application/xml:
          schema:
            type: 'object'
            properties:
              name:
                type: string
                description: hooray
      description: Pet object that needs to be added to the store
      required: true
    UserArray:
      content:
        application/json:
          schema:
            type: array
            items:
              $ref: '#/components/schemas/User'
      description: List of user object
      required: true
  securitySchemes:
    petstore_auth:
      description: |
        Get access to data while protecting your account credentials.
        OAuth2 is also a safer and more secure way to give you access.
      type: oauth2
      flows:
        implicit:
          authorizationUrl: 'http://petstore.swagger.io/api/oauth/dialog'
          scopes:
            'write:pets': modify pets in your account
            'read:pets': read your pets
    api_key:
      description: >
        For this sample, you can use the api key "special-key" to test the
        authorization filters.
      type: apiKey
      name: api_key
      in: header
  examples:
    Order:
      value:
        quantity: 1
        shipDate: '2018-10-19T16:46:45Z'
        status: placed
        complete: false
x-webhooks:
  newPet:
    post:
      summary: New pet
      description: Information about a new pet in the systems
      operationId: newPet
      tags:
        - pet
      requestBody:
        content:
          application/json:
            schema:
              $ref: "#/components/schemas/Pet"
      responses:
        "200":
          description: Return a 200 status to indicate that the data was received successfully

`;
const nativeObject = yaml.load(yamlStr);

export default function DocumentationPage() {
  return (
    <>
      <SEO title="documentation" canonicalPath={'/documentation'} />

      <Header brandLogo="assets/img/core-img/ipfindable-io-logo-transparent-250x90.webp" headerStyle="header-2" />
      <Divider />
      <RedocStandalone
        spec={JSON.parse(JSON.stringify(nativeObject))}
        options={{
          nativeScrollbars: false,
          theme: {
            colors: { primary: { main: 'blue' } },
            typography: {
              fontSize: '14px',
              code: {
                fontSize: '13px'
              }
            }
          },
          lazyRendering: true
        }}
      />

      <Footer footerLogo="assets/img/core-img/ipfindable-io-logo-white-250x90.webp" footerStyle="footer-2" />
    </>
  );
}
