module.exports = [
  {
    id: 1,
    showcaseSidebar: true,
    showCaseDown: true,
    page: 'Blog_1',
    path: 'ipfindable-io-show-content-based-on-visitors-country-state-or-city',
    title: `Show content based on visitor's country, state or city`,
    image: 'assets/img/blog-img/city-image.webp',
    catagory: 'Business',
    date: '13 July 2022',
    readTime: '3 min',
    haveVideo: 'false',
    excerpt: `Changing website content based on a visitor's geo location to make the customer having a personalized experience.`,
    tags: ['Business', 'Geo']
  },
  {
    id: 2,
    showcaseSidebar: true,
    showCaseDown: true,
    page: 'Blog_2',
    path: 'ipfindable-io-gdpr-compliance-for-eu-region-customers',
    title: 'GDPR compliance for EU region customers',
    image: 'assets/img/blog-img/gdpr-law.webp',
    catagory: 'News',
    date: '13 July 2022',
    readTime: '2 min',
    haveVideo: 'false',
    excerpt: 'GDPR that requires businesses to protect the personal data and privacy of EU region based users.',
    tags: ['Business', 'Compliance']
  },
  {
    id: 3,
    showcaseSidebar: true,
    showCaseDown: true,
    page: 'Blog_3',
    path: 'ipfindable-io-block-your-service-to-specific-countries',
    title: 'Block your service to specific country or region',
    image: 'assets/img/blog-img/country-smart-things.webp',
    catagory: 'Tech',
    date: '13 July 2022',
    readTime: '4 min',
    haveVideo: 'false',
    excerpt: 'Ensure your users have the rights to your service for compliance and security purposes.',
    tags: ['Business', 'Security']
  }
];
